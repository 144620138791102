var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-item-group',[_c('v-btn',{staticClass:"px-7 mr-4",attrs:{"color":"error","dark":"","rounded":"","outlined":"","data-cy":"cancel-btn"},on:{"click":function($event){return _vm.Cancel()}}},[_vm._v(" Cancel ")]),(
				_vm.authModule.IsReviewer &&
					_vm.headerModule.GetStepperIndex ===
						_vm.headerModule.GetStepperItems.length - 1
			)?_c('v-btn',{staticClass:"mr-4 px-7",attrs:{"color":"error","dark":"","rounded":"","data-cy":"reject-btn"},on:{"click":function($event){return _vm.RejectAndClose()}}},[_vm._v(" Reject Zone ")]):_vm._e(),_c('v-btn',{staticClass:"px-7",attrs:{"color":"primary","dark":"","rounded":"","data-cy":"next-btn"},on:{"click":function($event){_vm.headerModule.GetStepperIndex ===
				_vm.headerModule.GetStepperItems.length - 1
					? _vm.SaveAndClose()
					: _vm.NextStep()}}},[_vm._v(" "+_vm._s(_vm.headerModule.GetStepperIndex === _vm.headerModule.GetStepperItems.length - 1 ? _vm.authModule.IsReviewer ? 'Confirm Zone' : 'Request Review' : 'Next')+" ")])],1),(_vm.headerModule.GetStepperIndex !== 0)?_c('v-btn',{staticClass:"px-7",attrs:{"color":"primary","fixed":"","dark":"","bottom":"","rounded":"","left":"","data-cy":"previous-btn"},on:{"click":function($event){return _vm.PreviousStep()}}},[_c('v-icon',{staticClass:"pr-2"},[_vm._v("mdi-arrow-left")]),_vm._v(" Previous ")],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }