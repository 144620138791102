











































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { debounce } from 'ts-debounce';
import NoData from '@/components/datadisplay/NoData.vue';
import StepperNavigation from '@/components/zones/stepperNavigation.vue';

// Component imports
import { SupplierZonePostalComponent } from '@/classes/supplierZones/supplierZonePostalComponent';
import { IGroupedSupplierZone } from '@/classes/supplierZones/IGroupedSupplierZone';
import { ISupplierZonePostalComponent } from '@/classes/supplierZones/ISupplierZonePostalComponent';
import { PostalComponent } from '@/classes/supplierZones/postalComponent';
import LoadingData from '@/components/datadisplay/LoadingData.vue';

// State module imports
import { ZonesModule } from '@/store/zones/zones';
import { SuppliersModule } from '@/store/suppliers/suppliers';
import { AuthStore } from '@/store/auth/auth';

// Service Imports
import { SupplierZoneService } from '@/services/supplierZones/supplierZoneService';

@Component({
	components: {
		NoData,
		StepperNavigation,
		LoadingData,
	},
})
export default class RegionStep extends Vue {
	search = '';
	zonesModule = ZonesModule;
	authModule = AuthStore;
	isLoading = true;

	supplierZoneService = new SupplierZoneService();

	searchedPostalComponents: PostalComponent[] = [];
	delayedSearch = debounce(this.GetPostalComponents, 200);

	@Watch('search')
	onSearch(val: string) {
		if (!val) {
			this.searchedPostalComponents = [];
		}
		if (val && val.length >= 2) {
			this.delayedSearch(val);
		}
	}

	async GetPostalComponents(search: string) {
		this.searchedPostalComponents = this.ExistsInCoverage(
			await this.supplierZoneService.GetPostalComponents(search)
		);
	}

	AddPostalComponent(component: PostalComponent) {
		let newZoneComponent = new SupplierZonePostalComponent();

		// Check to see if the component to add already exists in the update list
		const exists = this.zonesModule.GetPostalComponentUpdates.find(
			xx => xx.PostalComponentId === component.Id
		);

		// If it doesn't exist update the new instance created to have the right properties
		if (!exists) {
			newZoneComponent.PostalComponent = component;
			newZoneComponent.PostalComponentId = component.Id;
			newZoneComponent.ZoneId = Number(this.$route.params.zoneId);

			// Map the length and reduce (basically sum them)
			// So get the length of all the grouped components and add them to get length
			// Inverse them to negative so that you can reference them
			// Negatives add to db :)
			newZoneComponent.Id = -Math.abs(
				this.zonesModule.GetSelectedZoneGroup.map(
					xx => xx.Components.length
				).reduce((sum, current) => sum + current, 0)
			);
		} else {
			// Otherwise set the component instantiated to be the same as the one in the update list
			newZoneComponent = exists;
		}

		let groupedComponents = this.zonesModule.GetSelectedZoneGroup;

		// Using the hierarchy of the components to get the index of the group
		// Once the group is acquired we can add to the correct group
		const index = groupedComponents.findIndex(
			xx => xx.Key.Level === component.Level
		);

		// If group doesn't exist, create it and add a component to the list
		if (index === -1) {
			const group = {
				Key: {
					Level: component.Level,
				},
				Components: [newZoneComponent],
			} as IGroupedSupplierZone;
			groupedComponents.push(group);
		} else {
			// Otherwise push a new component to the group children components
			groupedComponents[index].Components.unshift(newZoneComponent);
		}

		// Orders the groups so that they are in level order
		// Meaning Locations > Countries > Regions > Counties > Postal District > Postal Sector
		groupedComponents = groupedComponents.sort(
			(first, second) => 0 - (first.Key.Level > second.Key.Level ? -1 : 1)
		);

		// Updates the state zones grouping
		this.zonesModule.UpdateSelectedGroupZone(groupedComponents);

		this.zonesModule.TrackPostalComponentChanges(newZoneComponent);
	}

	async created() {
		if (SuppliersModule.GetSelectedSupplier.SupplierId) {
			const selectedZoneGroup = await this.supplierZoneService.GetSupplierZoneGrouped(
				SuppliersModule.GetSelectedSupplier.SupplierId,
				parseInt(this.$route.params.zoneId)
			);

			await this.zonesModule?.UpdateSelectedGroupZone(selectedZoneGroup);
			this.isLoading = false;
		}
	}

	DeletePostalComponent(component: ISupplierZonePostalComponent) {
		let groupedComponents = this.zonesModule.GetSelectedZoneGroup;

		const index = groupedComponents.findIndex(
			xx => xx.Key.Level === component.PostalComponent?.Level
		);

		groupedComponents[index].Components = groupedComponents[
			index
		].Components.filter(xx => xx.Id !== component.Id);

		groupedComponents = groupedComponents.filter(
			xx => xx.Components.length !== 0
		);

		this.zonesModule.UpdateSelectedGroupZone(groupedComponents);

		this.zonesModule.TrackPostalComponentChanges(component);
	}

	// This method is used to check the search list and grouped components
	// It checks to see if there are any items from the search already present in the list
	// If they do exist in the list, filter the search list to exclude them
	ExistsInCoverage(components: Array<PostalComponent>) {
		for (
			let index = 0;
			index < this.zonesModule.GetSelectedZoneGroup.length;
			index++
		) {
			const element = this.zonesModule.GetSelectedZoneGroup[index];
			components = components.filter(
				xx =>
					!element.Components.find(yy => yy.PostalComponent?.Code === xx.Code)
			);
		}
		return components;
	}

	GetHierarchyName(level: number) {
		switch (level) {
			case 0:
				return 'Locations';
			case 1:
				return 'Countries';
			case 2:
				return 'Regions';
			case 3:
				return 'Counties';
			case 4:
				return 'Postcode District';
			case 5:
				return 'Postcode Sector';
		}
	}

	Save() {
		if (
			!this.authModule.IsReviewer &&
			this.zonesModule.GetPostalComponentUpdates.length > 0
		) {
			this.supplierZoneService
				.UpdateSupplierZonePostalComponents(
					this.zonesModule.GetPostalComponentUpdates
				)
				.then(() => {
					this.zonesModule.Reset();
				});
		}

		this.$router.push(`/zones/${this.$route.params.zoneId}/service`);
	}
}
