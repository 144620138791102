import { IPostalComponent } from './IPostalComponent';
import { ISupplierZonePostalComponent } from './ISupplierZonePostalComponent';
import { PostalComponent } from './postalComponent';
import { ZoneStatus } from './zoneStatus';

export class SupplierZonePostalComponent
	implements ISupplierZonePostalComponent {
	public Id = 0;
	public ZoneId = 0;
	public PostalComponentId = 0;

	public Status: ZoneStatus = ZoneStatus.Draft;

	public PostalComponent: IPostalComponent = new PostalComponent();

	// constructor(){

	// }
}
