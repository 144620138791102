export class PostalComponent {
	public Id = 0;
	public Level = 0;
	public ParentId = 0;
	public Code = '';
	public Name = '';

	// constructor(){

	// }
}
