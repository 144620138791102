var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticStyle:{"height":"100%","padding":"0"},attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"height":"100%"},attrs:{"gutters":""}},[(!_vm.authModule.IsReviewer)?_c('v-col',{staticClass:"border-column",staticStyle:{"height":"100%"},attrs:{"cols":"12","lg":"3","data-cy":"RegionLeftColumn"}},[_c('h2',[_vm._v("Add Coverage")]),_c('v-text-field',{staticClass:"rounded-0 px-2",attrs:{"disabled":_vm.isLoading,"dense":"","prepend-inner-icon":"mdi-magnify","placeholder":"Search Coverage","solo":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-row',{staticStyle:{"overflow-y":"scroll","height":"90%"}},[_c('v-col',{staticClass:"pr-0 mt-1",staticStyle:{"height":"90%"}},[(_vm.ExistsInCoverage(_vm.searchedPostalComponents).length === 0)?_c('no-data',{attrs:{"noDataText":'No Results or Already Covered',"noDataImage":'mdi-text-search'}}):_vm._e(),_vm._l((_vm.ExistsInCoverage(_vm.searchedPostalComponents)),function(component){return _c('v-card',{key:component.Id,staticClass:"d-flex flex-no-wrap justify-space-between",attrs:{"dense":""}},[_c('v-card-subtitle',[_vm._v(_vm._s(component.Name))]),_c('v-btn',{staticStyle:{"margin":"7px"},attrs:{"icon":"","fab":"","small":"","right":""},on:{"click":function($event){return _vm.AddPostalComponent(component)}}},[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-plus-circle ")])],1)],1)})],2)],1)],1):_vm._e(),_c('v-col',{staticStyle:{"height":"100%"},attrs:{"data-cy":"RegionRightColumn","cols":"12","xl":_vm.authModule.IsReviewer ? 12 : 9,"lg":_vm.authModule.IsReviewer ? 12 : 9}},[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Selected Service Coverage")])])],1),_c('v-row',{staticStyle:{"overflow-y":"scroll","height":"90%"}},[_c('v-col',{staticStyle:{"height":"90%","padding-top":"0"},attrs:{"cols":"12"}},[(
								_vm.zonesModule.GetSelectedZoneGroup.length === 0 && !_vm.isLoading
							)?_c('no-data',{attrs:{"noDataText":'No Zones Created',"noDataImage":'mdi-dump-truck'}}):_vm._e(),(_vm.isLoading)?_c('loading-data',{attrs:{"loadingDataText":'Getting Coverage...'}}):_vm._e(),(!_vm.isLoading)?_c('v-list',_vm._l((_vm.zonesModule.GetSelectedZoneGroup),function(postalComponents){return _c('v-list-group',{key:postalComponents.Key.Level,attrs:{"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-row',[_c('v-col',{attrs:{"align-self":"center"}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
														_vm.GetHierarchyName(postalComponents.Key.Level)
													)}})],1),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-list-item-title',{staticStyle:{"text-align":"right"},attrs:{"data-cy":"list-item-header-number"}},[_vm._v(" "+_vm._s(postalComponents.Components.length)+" ")])],1)],1)],1)]},proxy:true}],null,true)},[_c('v-virtual-scroll',{attrs:{"height":postalComponents.Components.length > 5
											? 300
											: postalComponents.Components.length * 55,"item-height":50,"items":postalComponents.Components},scopedSlots:_vm._u([{key:"default",fn:function(ref){
											var item = ref.item;
return [_c('v-list-item',{key:item.PostalComponent.Id},[_c('v-list-item-content',{staticStyle:{"margin-left":"20px","padding":"0"}},[_c('v-list-item-title',[_c('v-avatar',{attrs:{"size":"35"}},[_c('span',[_vm._v(_vm._s(item.PostalComponent.Name.substring(0, 2)))])]),_c('span',{staticClass:"pl-3"},[_vm._v(_vm._s(item.PostalComponent.Name))])],1),(!_vm.authModule.IsReviewer)?_c('v-btn',{attrs:{"icon":"","fab":"","small":"","absolute":"","right":""},on:{"click":function($event){return _vm.DeletePostalComponent(item)}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1)],1),_c('v-divider')]}}],null,true)})],1)}),1):_vm._e()],1)],1)],1)],1)],1),_c('StepperNavigation',{on:{"next-step":_vm.Save}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }