











































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

// State module imports
import { HeaderModule } from '@/store/header/header';
import { ZonesModule } from '@/store/zones/zones';
import { AuthStore } from '@/store/auth/auth';

@Component({
	components: {},
})
export default class StepperNavigation extends Vue {
	headerModule = HeaderModule;
	zonesModule = ZonesModule;
	authModule = AuthStore;

	created() {
		this.headerModule.UpdateStepperIndex(this.$route.meta.stepperIndex);
	}

	PreviousStep() {
		// const ind = this.headerModule.GetStepperIndex;
		// this.headerModule.UpdateStepperIndex(ind - 1);
		this.$router.back();
	}

	NextStep() {
		const ind = this.headerModule.GetStepperIndex;
		this.headerModule.UpdateStepperIndex(ind + 1);
		this.$emit('next-step');
	}

	SaveAndClose() {
		this.headerModule.UpdateStepperIndex(0);
		this.$emit('next-step');
	}

	RejectAndClose() {
		// this.headerModule.UpdateStepperIndex(0);
		this.$emit('rejected');
	}

	Cancel() {
		this.headerModule.UpdateStepperIndex(0);
		this.$router.replace('/zones');
	}
}
